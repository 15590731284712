import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const SpotTypesChart = ({ data }) => {
  const spotTypes = {
    'Floor': data['Floor'] || 0,
    'Tread': data['Tread'] || 0,
    'Double Floor': data['Double Floor'] || 0,
  }
  
  const total = Object.values(data).reduce((sum, val) => sum + val, 0)
  const knownTotal = Object.values(spotTypes).reduce((sum, val) => sum + val, 0)
  const otherCount = total - knownTotal

  const chartData = {
    labels: [...Object.keys(spotTypes)],
    datasets: [{
      data: [...Object.values(spotTypes), otherCount],
      backgroundColor: [
        '#1969F9',  // Floor - blue
        '#28a745',  // Tread - green
        '#17a2b8',  // Double Floor - cyan
        '#f8f9fa'   // Other - light gray
      ],
      borderWidth: 1
    }]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '65%',
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          usePointStyle: true,
          padding: 20,  // Space between legend items
          boxWidth: 8   // Size of the colored point
        }
      },
      datalabels: {
        color: '#fff',
        formatter: (value, ctx) => {
          const total = ctx.dataset.data.reduce((sum, val) => sum + val, 0)
          const percentage = ((value / total) * 100).toFixed(1)
          if (percentage < 5) return '' // Don't show labels for small segments
          return `${percentage}%`
        },
        font: {
          weight: 'bold'
        }
      }
    }
  }

  return (
    <div style={{ height: 260 }}>
      <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

export default SpotTypesChart 