import React from 'react'
import { ResponsiveCalendar } from '@nivo/calendar'

const ClassesCalendar = ({ data, fromYear }) => {
  const currentYear = new Date().getFullYear()
  const fromDateStr = `${fromYear}-01-02` // January 1st of fromYear
  const toDateStr = `${currentYear}-12-31` // December 31st of currentYear

  return (
    <div style={{ height: `${(parseInt(toDateStr) - parseInt(fromDateStr) + 1) * 100}px` }}>
      <ResponsiveCalendar
        data={data}
        from={fromDateStr}
        to={toDateStr}
        emptyColor="#eeeeee"
        colors={['#1969F9', '#1251D6', '#0C3BB3', '#072990', '#041D77']}
        margin={{ left: 14 }}
        yearLegendOffset={6}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        monthBorderWidth={0}
        dayBorderColor="#ffffff"
        isInteractive={false}
        theme={{
          labels: {
            text: {
              fontWeight: 'bold',
              fontSize: 10,
              fill: '#222222'
            }
          }
        }}
      />
    </div>
  )
}

export default ClassesCalendar 