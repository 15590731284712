import 'regenerator-runtime/runtime.js'
import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Modal } from 'bootstrap'

import mergeWorkouts from './utils/merge_workouts'
import { getClassSessionFromUrl, urlFor } from './utils/url_helper'

import LoadingSpinner from './loading_spinner'

import HeaderCta from './header_cta'
import Datepicker from './filtering/datepicker'
import RegionSelect from './filtering/region_select'
import InstructorSelect from './filtering/instructor_select'
import LocationSelect from './filtering/location_select'
import QuickFilters from './filtering/quick_filters'
import WorkoutRow from './workout_row'
import SelectedClassModal from './selected_class_modal'
import LoginModalSelector from './login_modal_selector'

import { getAuthToken } from './api'

function App({ regions }) {
  const [region, setRegion] = useState(null)

  const changeRegion = (newRegion) => {
    setRegion(null)
    localStorage.setItem('preferredRegion', newRegion.name)

    setTimeout(() => {
      setRegion(newRegion)
    }, 500)
  }

  useEffect(() => {
    const savedRegion = localStorage.getItem('preferredRegion')
    setRegion(regions.find((r) => r.name === savedRegion) || regions[0])
  })

  return (
    <div className="react-container">
      <RegionSelect
        regions={regions}
        region={region}
        setRegion={changeRegion}
      />
      {region && <Schedule region={region} />}
    </div>
  )
}

const Schedule = (props) => {
  const { region } = props
  const { locations } = region
  const [currentUser, setCurrentUser] = useState({})
  const [workouts, setWorkouts] = useState([])
  const [allInstructors, setAllInstructors] = useState([])
  const [selectedClassSession, setSelectedClassSession] = useState(null)
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [selectedInstructors, setSelectedInstructors] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([locations[0]])
  const [anyFriends, setAnyFriends] = useState(false)
  const [filters, setFilters] = useState({
    friendsOnly: false,
    sfCity: false,
    doubleFloor: false,
    equipment: null,
  })

  const refreshSelf = useCallback(async () => {
    const response = await fetch('/reservations/self')
    const result = await response.json()
    concatWorkouts(result.class_sessions)
    setCurrentUser(result.currentUser)
  }, [])

  useEffect(() => {
    refreshSelf()

    const instructorMap = new Map()

    const processInstructors = (workouts) => {
      workouts.forEach((workout) => {
        if (workout.instructor_ids.length === 1) {
          const id = workout.instructor_ids[0]
          const name = workout.instructor_names[0]
          if (!instructorMap.has(id)) {
            instructorMap.set(id, { value: id, label: name })
          }
        }
      })
      const sortedInstructors = Array.from(
        instructorMap.values()
      ).sort((a, b) => a.label.localeCompare(b.label))
      setAllInstructors(sortedInstructors)
    }

    locations.forEach(async (location) => {
      const response = await fetch(
        `/schedule.json?week=0&location_id=${location.value}`
      )
      const result = await response.json()
      concatWorkouts(result.class_sessions)
      processInstructors(result.class_sessions)
    })

    fetch('/reservations/friends')
      .then((response) => response.json())
      .then((result) => {
        concatWorkouts(result.class_sessions)
        processInstructors(result.class_sessions)
      })

    fetch('/reservations/everybody')
      .then((response) => response.json())
      .then((result) => {
        concatWorkouts(result.class_sessions)
        processInstructors(result.class_sessions)
      })

    locations.forEach(async (location) => {
      for (let week = 1; week <= 2; week++) {
        const response = await fetch(
          `/schedule.json?week=${week}&location_id=${location.value}`
        )
        const result = await response.json()
        concatWorkouts(result.class_sessions)
        processInstructors(result.class_sessions)
      }
    })
  }, [locations, refreshSelf])

  const concatWorkouts = (newResults) => {
    const anyFriendsFlag = newResults[0] && Boolean(newResults[0].friends)
    setAnyFriends((prevAnyFriends) => prevAnyFriends || anyFriendsFlag)
    setWorkouts((prevWorkouts) => mergeWorkouts(prevWorkouts, newResults))
  }

  useEffect(() => {
    checkDeepLink()
  }, [workouts])

  const changeDay = (newDate) => {
    setSelectedDate(newDate)
  }

  const updateInstructors = (newInstructors) => {
    setSelectedInstructors(newInstructors.map((i) => i.value))
  }

  const updateLocations = (newLocations) => {
    setSelectedLocations(newLocations)
  }

  const updateFriendsOnly = (newValue) => {
    setSelectedLocations([])
    setFilters((prevFilters) => ({
      ...prevFilters,
      friendsOnly: newValue,
    }))
  }

  const updateSfCity = (newValue) => {
    const newLocations = newValue ? locations.slice(0, 4) : []
    setSelectedLocations(newLocations)
    setFilters((prevFilters) => ({
      ...prevFilters,
      sfCity: newValue,
    }))
  }

  const updateDoubleFloor = (newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      doubleFloor: newValue,
    }))
  }

  const updateEquipment = (newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      equipment: newValue,
    }))
  }

  const checkDeepLink = () => {
    if (!getAuthToken(document.cookie)) return
    const classSessionId = getClassSessionFromUrl()
    if (classSessionId) {
      const foundSelection = workouts.find(
        (workout) => classSessionId === workout.id && workout.time
      )
      if (
        foundSelection &&
        (!selectedClassSession || foundSelection.id !== selectedClassSession.id)
      ) {
        setSelectedClassSession(foundSelection)
        requestAnimationFrame(() => {
        const modalElement = document.getElementById('selectedClassModal')
        const modal = new Modal(modalElement)
          modal.show()
        })
      }
    }
  }

  const handleSetSelectedClassSession = (classSession) => {
    setSelectedClassSession(classSession)
    if (classSession) {
      requestAnimationFrame(() => {
        const modalElement = document.getElementById('selectedClassModal')
        const modal = new Modal(modalElement)
        modal.show()
      })
    }
    const newPath = urlFor(classSession && classSession.id)
    if (window.location.href.replace(/\/+$/, '') !== newPath) {
      window.history.pushState({ path: newPath }, '', newPath)
    }
  }

  let filteredWorkouts = workouts

  if (selectedInstructors.length > 0) {
    filteredWorkouts = filteredWorkouts.filter(
      (workout) =>
        workout.reserved ||
        (workout.instructor_ids &&
          workout.instructor_ids.some((instructor_id) =>
            selectedInstructors.includes(instructor_id)
          ))
    )
  }
  if (selectedLocations.length > 0) {
    filteredWorkouts = filteredWorkouts.filter(
      (workout) =>
        workout.reserved ||
        selectedLocations.map((i) => i.value).includes(workout.location_id)
    )
  }

  if (filters.doubleFloor) {
    filteredWorkouts = filteredWorkouts.filter(
      (workout) => workout.reserved || workout.double_floor
    )
  }

  if (filters.equipment) {
    filteredWorkouts = filteredWorkouts.filter(
      (workout) => workout.reserved || workout.equipment === filters.equipment
    )
  }

  if (filters.friendsOnly) {
    filteredWorkouts = filteredWorkouts.filter(
      (workout) => workout.friends || workout.reserved
    )
  }

  const availableDates = new Set(
    filteredWorkouts.map((workout) => workout.date)
  )

  filteredWorkouts = filteredWorkouts.filter(
    (workout) => workout.date === selectedDate && !!workout.time
  )

  return (
    <React.Fragment>
      <LocationSelect
        locations={locations}
        selectedLocations={selectedLocations}
        updateLocations={updateLocations}
      />
      <InstructorSelect
        instructors={allInstructors}
        update={updateInstructors}
      />
      {anyFriends && (
        <QuickFilters
          regionName={region.name}
          updateDoubleFloor={updateDoubleFloor}
          updateSfCity={updateSfCity}
          updateFriendsOnly={updateFriendsOnly}
          updateEquipment={updateEquipment}
          showFriends={anyFriends}
          filters={filters}
        />
      )}
      <Datepicker
        selectedDate={selectedDate}
        availableDates={availableDates}
        update={changeDay}
      />

      <LoadingSpinner isLoading={filteredWorkouts.length === 0} />

      {filteredWorkouts.map((workout) => (
        <WorkoutRow
          key={workout.id}
          {...workout}
          setSelectedClassSession={handleSetSelectedClassSession}
        />
      ))}
      <SelectedClassModal
        classSession={selectedClassSession}
        setSelectedClassSession={handleSetSelectedClassSession}
        currentUser={currentUser}
        refreshSelf={refreshSelf}
      />
      <LoginModalSelector currentUser={currentUser} />
    </React.Fragment>
  )
}

export default App
