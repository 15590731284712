import React, { useEffect, useState } from 'react'
import { getAuthToken } from './api'
import { Modal } from 'bootstrap'

export default function LoginModalSelector({ currentUser }) {
  const [modalOpened, setModalOpened] = useState(false)

  const userMissing = !getAuthToken(document.cookie)

  const authMissing =
    currentUser &&
    Object.keys(currentUser).length &&
    !currentUser.connectedBarrys

  useEffect(() => {
    if (modalOpened) return
    if (userMissing || authMissing) {
      const myModal = new Modal(document.getElementById('loginModal'), {
        backdrop: 'static',
        keyboard: false,
      })
      myModal.show()
      setModalOpened(true)
    }
  }, [currentUser])

  return null
}
