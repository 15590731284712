import React from 'react'

export default function RegionSelect({ regions, region, setRegion }) {
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="regionDropdownButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <strong>{(region && region.name) || 'Switching...'}</strong>
      </button>

      <ul className="dropdown-menu" aria-labelledby="regionDropdownButton">
        {regions.map((r) => (
          <li key={r.name}>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                setRegion(r)
              }}
            >
              {r.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
