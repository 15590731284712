export function getAuthToken(cookieStr) {
  for (const str of cookieStr.split(';')) {
    const [key, value] = str.split('=')
    if (key.trim() === 'auth_token') {
      return value
    }
  }
  return null
}

function doFetch(endpoint, opts) {
  const headers = new Headers({
    'Key-Inflection': 'snake',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(document.cookie)}`,
  })
  return fetch(endpoint, { ...opts, headers })
}

export function startCheckout(classSessionID) {
  return doFetch(`/api/checkout?class_session_id=${classSessionID}`, {
    method: 'POST',
  }).then((response) => response.json())
}

export function createReservation(params) {
  return doFetch('/api/reservations', {
    method: 'POST',
    body: JSON.stringify({ ...params, platform: 'web' }),
  }).then((response) => response.json())
}

// deprecated

export function fetchRSVPs() {
  return doFetch('/api/rsvps').then((response) => response.json())
}

export function addRSVP(classSessionID) {
  return doFetch(`/api/rsvps?class_session_id=${classSessionID}`, {
    method: 'POST',
  })
}

export function removeRSVP(classSessionID) {
  return doFetch(`/api/rsvps/${classSessionID}`, { method: 'DELETE' })
}
