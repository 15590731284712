export const getClassSessionFromUrl = () => {
  const [, id] = window.location.pathname.match(/^\/barrys\/(\d+)/) || [false]
  return id
}

export const urlFor = (classSessionId) => {
  if (classSessionId) {
    return `${window.location.origin}/barrys/${classSessionId}`
  } else {
    return window.location.origin
  }
}
