function earlierOrder(one, two) {
  if (one.start_datetime < two.start_datetime) {
    return true
  } else if (one.start_datetime > two.start_datetime) {
    return false
  } else {
    return one.id < two.id
  }
}

function mergeWorkouts(currentList, newList) {
  var newSet = []
  var i1 = 0
  var i2 = 0

  while (i1 < currentList.length && i2 < newList.length) {
    if (currentList[i1].id == newList[i2].id) {
      newSet.push(Object.assign(currentList[i1], newList[i2]))
      i1++
      i2++
    } else if (earlierOrder(currentList[i1], newList[i2])) {
      newSet.push(currentList[i1])
      i1++
    } else {
      newSet.push(newList[i2])
      i2++
    }
  }
  return newSet.concat(currentList.slice(i1)).concat(newList.slice(i2))
}

export default mergeWorkouts
