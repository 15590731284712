import React from 'react'
import { parse, isPast, isFuture, addMinutes, formatDistanceToNow } from 'date-fns'
import ordinal from 'ordinal'

import { getAuthToken, removeRSVP, addRSVP } from './api'

import CheckoutButton from './checkout_button'

function ShareLink({ bookingLink }) {
  const openNativeShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Live streaming workouts!',
          url: 'https://trymotion.com',
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
    } else {
      alert('oops!')
    }
  }

  return (
    <div className="mt-2">
      <button
        className="share-button btn btn-info d-sm-none"
        type="button"
        title="Share"
        onClick={openNativeShare}
      >
        Share
      </button>
      <a href={bookingLink} className="btn btm-sm btn-info float-right">
        Go!
      </a>
    </div>
  )
}

function Countdown({ startTime }) {
  if (isPast(addMinutes(startTime, 20))) {
    return null // started more than 20 minutes ago
  }

  if (isFuture(addMinutes(startTime, -44))) {
    return null // at least 45 minutes away
  }

  const text = isPast(startTime)
    ? 'Happening now!'
    : `In ${formatDistanceToNow(startTime)}!`

  return (
    <div style={{ display: 'block', textAlign: 'center', fontSize: 12 }}>
      <strong>{text}</strong>
    </div>
  )
}

function StreamingButton(props) {
  const {
    id,
    location_name,
    bookingLink,
    start_datetime,
    isReserved,
    setIsReserved,
  } = props

  const startTime = parse(start_datetime)

  function onClickRemove() {
    setIsReserved(false)
    return removeRSVP(id).catch(() => setIsReserved(true))
  }

  function onClickAdd() {
    setIsReserved(true)
    return addRSVP(id).catch(() => setIsReserved(false))
  }

  function RsvpOrLogin(callback) {
    if (getAuthToken(document.cookie)) {
      return onClickAdd()
    } else {
      $('#loginModal').modal('show')
    }
  }

  if (isPast(addMinutes(startTime, 30))) {
    return <BookingLinkPast />
  }

  if (isPast(addMinutes(startTime, -10))) {
    return (
      <React.Fragment>
        <Countdown startTime={startTime} />
        <a href={bookingLink} className="btn btn-block btm-sm moving-gradient">
          <strong>Join in!</strong>
        </a>
      </React.Fragment>
    )
  }

  if (isReserved) {
    return (
      <React.Fragment>
        <Countdown startTime={startTime} />
        <button
          onClick={onClickRemove}
          className="btn btn-block btm-sm btn-danger"
          children="You're in 💪"
        />
        <ShareLink bookingLink={bookingLink} />
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Countdown startTime={startTime} />
      <button
        onClick={RsvpOrLogin}
        className="btn btn-block btm-sm btn-outline-danger bw-2"
        children="Count me in!"
      />
    </React.Fragment>
  )
}

function BookingButton(props) {
  const {
    active,
    at_capacity,
    available_spots,
    bookingLink,
    booking_time,
    class_type,
    double_floor,
    location_name,
    onClick,
    price_type,
    reserved,
    spot_name,
    start_datetime,
    waitlist_length,
    waitlist_position,
  } = props

  const isStreaming = class_type === 'streaming'

  if (price_type === 'paid') return <CheckoutButton {...props} />

  if (isStreaming) return <StreamingButton {...props} />

  if (isPast(parse(start_datetime))) return <BookingLinkPast />

  if (reserved) {
    return (
      <BookingLinkReserved
        spot_name={spot_name}
        url={bookingLink}
        waitlist_position={waitlist_position}
      />
    )
  }

  if (at_capacity)
    return <div className="font-weight-bold text-center">Class Full</div>

  if (isFuture(parse(booking_time))) {
    return <BookingLinkFuture url={bookingLink} />
  }

  if (location_name.match(/At Home/)) {
    return (
      <button className="btn btn-block btm-sm btn-outline-danger bw-2 disabled">
        Book on App
      </button>
    )
  }

  if (!available_spots.length) {
    return (
      <button
        onClick={onClick}
        className={`btn btn-block btm-sm btn-outline-secondary bw-2${
          active ? ' active' : ''
        }`}
      >
        {'Waitlist (' + waitlist_length + ')'}
      </button>
    )
  } else {
    return (
      <button
        onClick={onClick}
        className={`btn btn-block btm-sm btn-outline-danger bw-2${
          active ? ' active' : ''
        }`}
      >
        Reserve{double_floor && '!'}
      </button>
    )
  }
}

function BookingLinkPast() {
  return (
    <button className="btn btn-block btm-sm btn-outline-info bw-2" disabled>
      Past
    </button>
  )
}

function BookingLinkFuture(props) {
  const { url } = props

  return (
    <a
      href={url}
      target="_blank"
      className="btn btn-block btm-sm btn-outline-info bw-2"
    >
      Not Open
    </a>
  )
}

function BookingLinkReserved(props) {
  const { url, spot_name, waitlist_position } = props

  const buttonColor =
    spot_name && spot_name.includes('Waitlist') ? 'btn-secondary' : 'btn-danger'

  let label = spot_name
  if (!spot_name) {
    label = `You're in 💪`
  } else if (spot_name.includes('Waitlist') && waitlist_position) {
    label = `Waitlist (${ordinal(waitlist_position)})`
  }

  return (
    <a
      href={url}
      target="_blank"
      className={'btn btn-block btm-sm ' + buttonColor}
    >
      <span className="text-white">{label}</span>
    </a>
  )
}

export default BookingButton
