import React, { useState } from 'react'
import ClassesCalendar from './ClassesCalendar'

const ClassesCalendarSection = ({ stats }) => {
  const currentYear = new Date().getFullYear()
  const [showAllYears, setShowAllYears] = useState(false)
  
  const earliestYear = Math.min(...Object.keys(stats.daily_counts)
    .map(dateStr => new Date(dateStr).getFullYear()))
  const fromYear = showAllYears ? earliestYear : currentYear

  return (
    <div>
      <div className="mb-3">
        <ClassesCalendar 
          data={Object.entries(stats.daily_counts).map(([dateStr, count]) => ({
            day: dateStr,
            value: count
          }))}
          fromYear={fromYear}
        />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button 
          className="btn btn-sm btn-outline-primary"
          onClick={() => setShowAllYears(!showAllYears)}
        >
          {showAllYears ? 'Show Current Year' : 'Show All Years'}
        </button>
      </div>
    </div>
  )
}

export default ClassesCalendarSection 