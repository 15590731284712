import React from 'react'

function LoadingSpinner(props) {
  const isLoading = props.isLoading
  if (isLoading) {
    return (
      <div className="row">
        <div className="col-4 col-md-2 offset-4 offset-md-5">
          <div id="loading-spinner">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 79.77 123.83"
            >
              <title>Chevron_final</title>
              <polygon points="43.6 105.86 39.99 94.76 36.38 105.86 24.71 105.86 34.16 112.72 30.55 123.83 39.99 116.97 49.44 123.83 45.83 112.72 55.28 105.86 43.6 105.86" />
              <polygon points="32.53 35.57 56.16 14.65 39.63 0 0 35.09 0.01 64.28 32.53 35.57" />
              <polygon points="56.15 62.11 39.63 47.46 0 82.56 0 111.74 32.53 83.03 56.15 62.11" />
              <polygon points="41.52 30.99 79.77 64.75 79.77 35.58 58.06 16.34 41.52 30.99" />
              <polygon points="58.06 63.8 41.52 78.45 79.77 112.21 79.77 83.04 58.06 63.8" />
            </svg>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default LoadingSpinner
