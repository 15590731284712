import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import 'chartjs-adapter-date-fns'

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
)

export { default as ClassesCalendarSection } from './stats/ClassesCalendarSection'
export { default as ClassesOverTime } from './stats/ClassesOverTime'
export { default as ClassesByDay } from './stats/ClassesByDay'
export { default as InstructorsChart } from './stats/InstructorsChart'
export { default as LocationsChart } from './stats/LocationsChart'
export { default as SpotTypesChart } from './stats/SpotTypesChart'