import React from 'react'

export default function EquipmentFilter({ currentValue, update }) {
  function Option({ label, value }) {
    return (
      <button
        type="button"
        className={`btn btn-sm btn-outline-primary ${
          currentValue === value && 'active'
        }`}
        style={{ flexGrow: 1 }}
        onClick={() => update(value)}
      >
        {label}
      </button>
    )
  }

  return (
    <div
      className="btn-group float-right"
      role="group"
      style={{ width: '100%', flex: 1 }}
    >
      <Option label="All" value={null} />
      <Option label="Bodyweight" value={'none'} />
      <Option label="Bands" value={'bands'} />
      <Option label="Weights" value={'weights'} />
    </div>
  )
}
