import React from 'react'
import QuickFilterToggle from './quick_filter_toggle'
import EquipmentFilter from './equipment_filter'

const QuickFilters = ({
  regionName,
  filters,
  showFriends,
  updateFriendsOnly,
  updateSfCity,
  updateDoubleFloor,
  updateEquipment,
}) => {
  return (
    <div className="row mt-2">
      <div className="col-md-5">
        <p>
          <span className="text-muted mr-2">Quick filter:</span>
          {showFriends && (
            <QuickFilterToggle
              text="Friends"
              toggleValue={filters.friendsOnly}
              updateFilter={updateFriendsOnly}
            />
          )}
        </p>
      </div>

      {regionName === 'At Home' && (
        <div className="col-md-7">
          <EquipmentFilter
            currentValue={filters.equipment}
            update={updateEquipment}
          />
        </div>
      )}
    </div>
  )
}

export default QuickFilters
