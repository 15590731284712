import React from 'react'
import Select, { createFilter } from 'react-select'

const InstructorSelect = ({ instructors, update }) => {
  const handleChange = (selected, _action_type) => {
    update(selected)
  }

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      danger: '#eb0b0b',
      dangerLight: '#fcbebe',
      primary: '#3a3a3a',
      primary75: '#6b6b6b',
      primary50: '#929292',
      primary25: '#bababa',
    },
  })

  return (
    <Select
      isMulti
      name="instructors"
      options={instructors}
      className="basic-multi-select mt-1"
      classNamePrefix="select"
      onChange={handleChange}
      placeholder="All Instructors"
      filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      theme={selectTheme}
    />
  )
}

export default InstructorSelect
