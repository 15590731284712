import React, { useState } from 'react'
import Select from 'react-select'

const allOption = { value: 'all', label: 'All locations' }

const LocationSelect = ({ locations, selectedLocations, updateLocations }) => {
  if (locations.length < 2) {
    return null
  }

  const handleChange = (selected) => {
    if (
      selected &&
      selected.some((option) => option.value === allOption.value)
    ) {
      if (selectedLocations.length === locations.length) {
        updateLocations([])
      } else {
        updateLocations(locations)
      }
    } else {
      updateLocations(selected || [])
    }
  }

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      danger: '#eb0b0b',
      dangerLight: '#fcbebe',
      primary: '#3a3a3a',
      primary75: '#6b6b6b',
      primary50: '#929292',
      primary25: '#bababa',
    },
  })

  const customOptions = [allOption, ...locations]

  return (
    <Select
      value={selectedLocations}
      isMulti
      options={customOptions}
      onChange={handleChange}
      name="locations"
      className="basic-multi-select mt-1"
      classNamePrefix="select"
      placeholder="All Locations"
      theme={selectTheme}
    />
  )
}

export default LocationSelect
