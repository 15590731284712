import React, { useEffect, useState } from 'react'

import BookingButton from './booking_button'
import FriendSpots from './friend_spots'
import BarrysLogo from './assets/barrys_logo'
import InstagramLogo from './assets/instagram_logo'

export default function WorkoutRow(props) {
  const {
    time,
    location_name,
    class_type,
    classroom_display,
    instructor_names,
    friends,
    reserved,
    class_title,
    price_type,
    price,
    waitlist_length,
    setSelectedClassSession,
    everybody,
  } = props
  const [isReserved, setIsReserved] = useState(reserved)

  useEffect(() => {
    setIsReserved(reserved)
  }, [reserved])

  function renderClassTypeEmoji() {
    if (classroom_display.match(/outdoor/i)) return '☀️'
    if (location_name.match(/At Home/i)) return '📺'

    switch (class_type) {
      case 'lift':
        return '🏋️‍♀️'
      case 'release':
        return '🧘‍♀️'
      default:
        return ''
    }
  }

  const PriceTag = () => {
    if (!price) return null

    return <span className="badge badge-secondary mr-1 mb-1">{price}</span>
  }

  return (
    <div className="row border-bottom border-bottom-thick pb-2 mb-2">
      <div className="col">
        <div className="row">
          <div className="col mr-n4">
            <div>
              <strong onClick={() => setSelectedClassSession(props)}>
                {time}
              </strong>
              &nbsp;@ {location_name} {renderClassTypeEmoji()}
              <br />
              {instructor_names.join(', ')}
              <br />
            </div>
          </div>
          <div
            className="col-5 pt-1"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ flex: 1 }}>
              <BookingButton
                {...props}
                onClick={() => setSelectedClassSession(props)}
                isReserved={isReserved}
                setIsReserved={setIsReserved}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <PriceTag />
            <small>{class_title}</small>
          </div>
          {isReserved && waitlist_length > 0 && (
            <div>
              <small>{waitlist_length} on waitlist</small>
            </div>
          )}
        </div>

        <FriendSpots friends={friends} />
        <FriendSpots friends={everybody} primary={false} />
      </div>
    </div>
  )
}
