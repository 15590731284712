const endpointBase = 'https://barrysbootcamp.marianatek.com'

function doFetch(endpoint, token) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  })
  return fetch(endpointBase + endpoint, { headers })
    .then(handleResponse)
    .catch(handleError)
}

function handleResponse(response) {
  if (response.ok) {
    return response.json()
  } else {
    console.info(response.body)
    return Promise.reject({ error: 'There was an error!' })
  }
}

function handleError(error) {
  console.error(error)
  return Promise.reject({ error: 'There was an error!' })
}

////

export function fetchClassPaymentOptions({ id, token }) {
  return doFetch(`/api/customer/v1/classes/${id}/payment_options`, token)
}

export function fetchClassDetails({ id }) {
  return doFetch(`/api/customer/v1/classes/${id}`)
}
