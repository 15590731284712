import React from 'react'
import { Line } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const ClassesOverTime = ({ data }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'Classes',
        data: Object.values(data),
        borderColor: '#1969F9',
        backgroundColor: '#1969F9',
        tension: 0.2
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      }
    }
  }

  return (
    <div style={{ height: 260 }}>
      <Line data={chartData} options={options} />
    </div>
  )
}

export default ClassesOverTime 