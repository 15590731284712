import React from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const InstructorsChart = ({ data }) => {
  const chartData = {
    labels: data.map(([instructor]) => instructor),
    datasets: [
      {
        label: 'Classes',
        data: data.map(([_, count]) => count),
        backgroundColor: '#1969F9'
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        display: false  // Hide x-axis
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        color: '#000',
        anchor: 'end',
        align: 'end',
        offset: 4,
        font: {
          weight: 'bold'
        }
      }
    }
  }

  return (
    <div style={{ height: 260 }}>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

export default InstructorsChart 