import React from 'react'

function FriendSpots({ friends, primary = true }) {
  if (!friends) return null

  function renderFriend(friend) {
    return (
      <div
        key={friend.name}
        className={`badge badge-${primary ? 'primary' : 'info'} mr-1 mb-1`}
      >
        {friend.name}
        {friend.spot_name && ':'} {friend.spot_name}
      </div>
    )
  }

  return friends.map((friend) => {
    return renderFriend(friend)
  })
}

export default FriendSpots
