import React from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const ClassesByDay = ({ data }) => {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  
  const chartData = {
    labels: dayNames,
    datasets: [
      {
        label: 'Classes',
        data: dayNames.map(day => data[dayNames.indexOf(day)] || 0),
        backgroundColor: '#1969F9'
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        display: false,  // Hide y-axis
        max: function(context) {
          // Add 10% padding to the max value
          const max = context.chart.data.datasets[0].data.reduce((a, b) => Math.max(a, b), 0);
          return max * 1.15;
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        color: '#000',
        anchor: 'end',
        align: 'end',
        offset: 4,
        font: {
          weight: 'bold'
        }
      }
    }
  }

  return (
    <div style={{ height: 260 }}>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

export default ClassesByDay 