import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { createReservation } from './api'
import { fetchClassDetails, fetchClassPaymentOptions } from './barrys_api'

const SelectionButton = ({ active, title, onClick }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <Button
        active={active}
        variant="outline-secondary"
        size="sm"
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  )
}

const SpinnerInside = () => (
  <Spinner
    as="span"
    animation="border"
    size="sm"
    role="status"
    aria-hidden="true"
  />
)

function CreditSelect({ id, currentUser, creditId, setCreditId }) {
  const [options, setOptions] = useState()
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    fetchClassPaymentOptions({ id, token: currentUser.barrysAccessToken })
      .then(({ user_payment_options: opts }) => {
        setOptions(opts)
        if (opts.length === 1) setCreditId(opts[0].id)
      })
      .catch(({ error }) => setErrorMessage(error))
  }, [])

  if (errorMessage) {
    return <p style={{ marginTop: 10 }}>{errorMessage}</p>
  }
  if (options == null) {
    return <SelectionButton disabled title={<SpinnerInside />} />
  }
  if (options.length === 0) {
    return <p style={{ marginTop: 10 }}>No Credits Available!</p>
  }

  if (creditId) {
    const selectedOption = options.find((option) => creditId === option.id)
    return (
      <SelectionButton
        active
        title={selectedOption.description}
        onClick={() => setCreditId(null)}
      />
    )
  }

  return options.map((option) => (
    <SelectionButton
      key={option.id}
      title={option.description}
      onClick={() => setCreditId(option.id)}
    />
  ))
}

const MemoizedCreditSelect = React.memo(CreditSelect)

function SpotSelect({ id, spotId, setSpotId, show }) {
  const [spots, setSpots] = useState()
  const [spotTypes, setSpotTypes] = useState()
  const [selectedSpotType, setSelectedSpotType] = useState()

  useEffect(() => {
    fetchClassDetails({ id }).then(({ layout }) => {
      const availableSpots = layout.spots.filter((spot) => spot.is_available)
      setSpots(availableSpots)
      const availableSpotTypes = [
        ...new Set(availableSpots.map((spot) => spot.spot_type.name)),
      ]
      setSpotTypes(availableSpotTypes)
      if (availableSpotTypes.length === 1)
        setSelectedSpotType(availableSpotTypes[0])
    })
  }, [])

  if (!show || spots == null || spotTypes == null) return null

  if (spots.length === 0) {
    return (
      <SelectionButton
        active={spotId === 'Waitlist'}
        title="Join Waitlist"
        onClick={() => setSpotId('Waitlist')}
      />
    )
  }

  function SpotType() {
    if (selectedSpotType == null) {
      return spotTypes.map((spotType) => (
        <SelectionButton
          key={spotType}
          title={spotType}
          onClick={() => setSelectedSpotType(spotType)}
        />
      ))
    }
    return (
      <SelectionButton
        active
        title={selectedSpotType}
        onClick={() => setSelectedSpotType(null)}
      />
    )
  }

  if (selectedSpotType == null) {
    return spotTypes.map((spotType) => (
      <SelectionButton
        key={spotType}
        title={spotType}
        onClick={() => setSelectedSpotType(spotType)}
      />
    ))
  }

  function Spot() {
    if (spotId) {
      return (
        <SelectionButton
          active
          title={spots.find((spot) => spot.id === spotId).name}
        />
      )
    }
    return spots
      .filter((spot) => spot.spot_type.name === selectedSpotType)
      .reverse()
      .slice(0, 15) // only show up to 15 spots
      .map((spot) => (
        <SelectionButton
          key={spot.id}
          title={spot.name}
          onClick={() => setSpotId(spot.id)}
        />
      ))
  }
  return (
    <div>
      <SpotType />
      <Spot />
    </div>
  )
}

const MemoizedSpotSelect = React.memo(SpotSelect)

export default function BookingFlow({ id, currentUser, refreshSelf }) {
  const [creditId, setCreditId] = useState()
  const [spotId, setSpotId] = useState()

  const [booking, setBooking] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (creditId && spotId && !booking) {
      setBooking(true)

      createReservation({
        classSessionId: id,
        paymentOptionId: creditId,
        spotId: spotId,
      })
        .then((result) => {
          if (result.success) {
            setSuccess(true)
            refreshSelf()
          } else {
            alert('There was an error!')
          }
        })
        .catch(() => alert('There was an error!'))
    }
  }, [creditId, spotId])

  const FeedbackButton = () => (
    <SelectionButton
      active={success}
      title={success ? `You're In!` : <SpinnerInside />}
    />
  )

  return (
    <div>
      <MemoizedCreditSelect
        id={id}
        currentUser={currentUser}
        creditId={creditId}
        setCreditId={setCreditId}
      />
      <MemoizedSpotSelect
        id={id}
        spotId={spotId}
        setSpotId={setSpotId}
        show={!!creditId}
      />
      {booking && <FeedbackButton />}
    </div>
  )
}
