import React from 'react'

const QuickFilterToggle = ({ text, toggleValue, updateFilter }) => {
  const handleClick = (e) => {
    e.preventDefault()
    updateFilter(!toggleValue)
  }

  return (
    <a
      href="#"
      onClick={handleClick}
      className={`btn btn-sm mr-2 btn-outline-primary ${
        toggleValue ? 'active' : ''
      }`}
    >
      {text}
    </a>
  )
}

export default QuickFilterToggle
