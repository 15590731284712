import React from 'react'
import { format, parse } from 'date-fns'

const DateButton = ({ date, available, selected, update }) => {
  const handleClick = () => {
    update(date)
  }

  const colorClass = available
    ? 'btn-outline-secondary '
    : 'btn-outline-danger '
  const selectedClass = selected ? ' active' : ''

  const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
  const dayLabel = format(parsedDate, 'EEE').toUpperCase().slice(0, 2)

  return (
    <button
      className={`btn date-button text-uppercase ${colorClass}${selectedClass}`}
      onClick={handleClick}
    >
      {dayLabel}
    </button>
  )
}

export default DateButton
